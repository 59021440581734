import React, {Component} from 'react';

import Sidebar from './components/Sidebar';
import Main from './components/Main';
// import ContactForm from "./components/ContactForm";
import {MapContainer, TileLayer, Marker, Popup, Circle} from 'react-leaflet';

import lightsImage from './assets/images/lights.jpg';
import skuretImage from './assets/images/skuret.jpg';
import props1Image from './assets/images/props1.jpg';
import props2Image from './assets/images/props2.jpg';

import './App.css';

class App extends Component {
  render() {
    const center = [59.9854, 11.24091];
    const fillBlueOptions = {fillColor: 'blue'};

    return (
      <div className="app">
        <Sidebar className="app__sidebar" />
        <div className="app__content">
          <Main id="info">
            <h1>Info</h1>
            <p>
              Selfieskuret er en fotoboks du enkelt opererer selv. Det passer perfekt til bryllup, utdrikningslag,
              bursdag, julebord og enhver anledning som fortjener å huskes.
            </p>
            <p>
              For å sette den opp trenger vi ca 2,5 * 2,5 meter gulvplass, og tilgang til strøm. Takhøyde bør være minst
              2,2 meter. Vi trenger også et bord for å legge frem rekvisitter.
            </p>

            <div className="app__image">
              <img src={lightsImage} alt='Selfieskuret sett "utenfra"' />
            </div>

            <div className="app__image">
              <img src={skuretImage} alt="Selfieskuret sett fra brukerens perspektiv" />
            </div>

            <div className="app__image">
              <img src={props1Image} alt="Noen rekvisitter" />
            </div>

            <div className="app__image">
              <img src={props2Image} alt="Flere rekvisitter" />
            </div>
          </Main>

          <Main id="prices">
            <h1>Priser</h1>
            <p>
              Leie av skuret koster kr. 8000,-. Da har dere tilgang til Selfieskuret hele festkvelden/natten. Dette
              inkluderer også bakgrunn, fotolys, utløserknapp og rekvisitter. Tilkjøring og henting innen ca 1 times
              kjøring fra Sørumsand er også inkludert i prisen.
            </p>
            <p>
              Alle bilder som blir tatt gjøres tilgjengelig for deltakerne på festen umiddelbart i et nettalbum.
              Dette albumet er passordbeskyttet, slik at kun festens gjester får tilgang til det. For at dette albumet
              skal være tilgjengelig, må vi ha tilgang til wifi i lokalet. Alle bilder blir uansett sendt til arrangør
              etter festen er over.
            </p>
            <p>
              Vanligvis kommer vi ca. 2 timer før festen starter, og rigger opp. Nedrigg kan gjøres etter avtale,
              vanligvis dagen etter festen. Her er det rom for fleksibilitet så om du har noen andre ønsker, gi oss
              beskjed om dette så finner vi ut av det.
            </p>
          </Main>

          <Main id="contact">
            <h1>Kontakt</h1>
            <div>
              <p>
                Vi holder til i Sørumsand, og kjøring innenfor ca 1 times tid er inkludert i leieprisen. Det vil si sånn
                ca innenfor dette området:
              </p>
              <div id="map">
                <MapContainer center={center} zoom={9} scrollWheelZoom={false} style={{height: '100%'}}>
                  <Circle center={center} pathOptions={fillBlueOptions} radius={40000} />
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={center}></Marker>
                </MapContainer>
              </div>
            </div>
            <p>
              Send oss en melding på <a href="mailto:foto@selfieskuret.no">foto@selfieskuret.no</a> for et uforpliktende
              tilbud, så hører du fra Christine eller Håkon ganske snart.
            </p>
            {/* <ContactForm /> */}
          </Main>
          <footer className="app__footer">
            <div>Denne siden benytter cookies via Google Analytics til å føre statistikk.</div>
            <div>Org. nr: 915 280 773</div>
          </footer>
        </div>
      </div>
    );
  }
}

export default App;
