import React from "react";
import cn from 'classnames';

const Main = ({ className, id, children }) => {
  return (
    <div id={id} className={cn('app__main', className)}>
      {children}
    </div>
  );
};

export default Main;
