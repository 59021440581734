import React from "react";
import cn from "classnames";

import Logo from "../Logo";
import "./sidebar.css";

const smoothScrollTo = e => {
  e.preventDefault();
  const scrollTarget = document.querySelector(
    `${e.currentTarget.getAttribute("href")}`
  ).offsetTop;

  window.scrollTo({ top: scrollTarget - 20, behavior: "smooth" });
};

const Sidebar = ({ className }) => {
  return (
    <div className={cn("sidebar", className)}>
      <Logo className="sidebar__logo" />
      <div className="sidebar__tagline">

      </div>
      <nav className="sidebar__nav nav">
        <a href="#info" onClick={smoothScrollTo} className="nav__link">
          Info
        </a>
        <a href="#prices" onClick={smoothScrollTo} className="nav__link">
          Priser
        </a>
        <a href="#contact" onClick={smoothScrollTo} className="nav__link">
          Kontakt
        </a>
      </nav>
      <footer className="sidebar__footer"></footer>
    </div>
  );
};

export default Sidebar;
